<template>
  <div>
    <big-title v-if="isMounted">
      Nuevo <span>Beneficiario</span>.
      <p class="text-subtitle mt-6">Agrega la información necesaria para registrar al Beneficiario.</p>
    </big-title>
    <beneficiary-form :new-record="newRecord" role="client" ></beneficiary-form>
  </div>
</template>

<script>
import BeneficiaryForm from '../../components/profile_shared/BeneficiaryForm';

export default {
  data(){
    return {
      isMounted: false,
      newRecord: true
    }
  },
  components: {
    BeneficiaryForm
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
  .alt-radio-text {
    margin-left: 10px;
    color: rgba(var(--vs-success), 1);
  }
</style>